<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ name: 'admin' }">管理员</el-breadcrumb-item>
        <el-breadcrumb-item>{{ infoForm.id ? '编辑管理员' : '添加管理员' }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <el-button type="primary" @click="goBackPage" icon="arrow-left">返回列表</el-button>
        <!--<el-button type="primary" @click="testa" icon="arrow-left">测试</el-button>-->
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
        
          <el-form-item label="登录名" prop="account">
            <el-input v-model="infoForm.account"></el-input>
          </el-form-item>
           <el-form-item label="公司名称" prop="sales">
            <el-input v-model="infoForm.company" disabled></el-input>
            <span style="color:gray;">联系销售员进行修改 </span>

          </el-form-item>
        
           <el-form-item label="登录密码" prop="pwd">
            <el-input v-model="infoForm.pwd" ></el-input>
          </el-form-item>
        
          <el-form-item>
            <el-button v-if="infoForm.id > 0" type="primary" @click="saveAdminInfo"
              >确定保存</el-button
            >
            <el-button @click="goBackPage">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/config/api';

export default {
  data() {
    return {
      change: false,
      infoForm: {
        id: 0,
        user: '',
        account:'',
        sales:'',
        froms:'',
        pwd:'',
        newpassword: '',
        password: ''
      },
      infoRules: {
        account: [{ required: true, message: '请输入登录名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    };
  },
  methods: {
    
    goBackPage() {
      this.$router.go(-1);
    },
    saveAdminInfo() {
      let user = this.infoForm;
      if (this.change == true) {
        let password = user.newpassword;
        if (password == undefined) {
          user.newpassword = '';
        }
        if (password != undefined && password.replace(/(^\s*)|(\s*$)/g, '').length < 6) {
          this.$message({
            type: 'error',
            message: '密码请大于6个字符'
          });
          return false;
        }
      }
      this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          this.axios
            .post('admin/adminSave', {
              user: user,
              change: this.change
            })
            .then((response) => {
              if (response.data.errno === 0) {
                this.$message({
                  type: 'success',
                  message: '保存成功'
                });
                              this.$router.go(-1);

              } else if (response.data.errno === 400) {
                this.$message({
                  type: 'error',
                  message: response.data.errmsg
                });
              } else {
                this.$message({
                  type: 'error',
                  message: '保存失败'
                });
              }
            });
        } else {
          return false;
        }
      });
    },
   
    getInfo() {
      //加载品牌详情
      let that = this;
      this.axios
        .post('admin/adminDetail', {
          id: that.infoForm.id
        })
        .then((response) => {
          if (response.data.errno === 0) {
            let resInfo = response.data.data;
            that.infoForm = resInfo;
          }
        });
    }
  },
  components: {},
  mounted() {
    this.infoForm.id = this.$route.query.id || 0;
    this.getInfo();
  }
};
</script>

<style>
.image-uploader {
  height: 105px;
}

.image-uploader .el-upload {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.image-uploader .el-upload:hover {
  border-color: #20a0ff;
}

.image-uploader .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 187px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader .image-show {
  width: 187px;
  height: 105px;
  display: block;
}

.image-uploader.new-image-uploader {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader.new-image-uploader .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader.new-image-uploader .image-show {
  width: 165px;
  height: 105px;
  display: block;
}
</style>
